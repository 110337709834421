import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  SvgIcon,
  Typography
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Globe } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { updatePersonalization } from 'src/actions/userActions';
import FlagIcon from 'src/components/FlagIcon.js';
import { getLanguageByKey, LanguagesEnum, THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import { accountSelector } from 'src/selectors/selectors';

export default function LanguagePopover({ showLang = false }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [atBottom, setAtBottom] = useState(false); // Track if the user reached the bottom

  const { translations } = useSelector(state => state?.userInterface);
  const { settings, saveSettings } = useSettings();
  const paperRef = useRef(null); // Reference to the Paper component
  const account = useSelector(accountSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const arrowRef = useRef(null);
  const { search } = useLocation();

  const handleScroll = () => {
    if (paperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = paperRef.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    let lang = queryParams?.get('lang')?.toUpperCase();
    if (lang == 'IL') lang = 'IW'; //to acknowledge old links before changing to iw
    if (lang) {
      queryParams.delete('lang');
      history.replace({
        search: queryParams.toString()
      });
      const language = LanguagesEnum?.[lang];
      changeLanguage(language);
      // .then(() => window.location.reload());
    }
  }, []);

  // ltr = english, rtl = hebrew
  const changeLanguage = async language => {
    const newSettings = {
      direction: language.direction,
      responsiveFontSizes: settings.responsiveFontSizes,
      theme: settings.theme,
      lang: language.lang
    };

    if (account && account?.user) {
      const siteThemeOid = THEMES[newSettings.theme].oid;
      const languageOid = language.value;
      await dispatch(updatePersonalization(siteThemeOid, languageOid));
    }

    await saveSettings(newSettings);
    handleClickAway();
  };

  const openLangPopper = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const currentLang = useMemo(() => getLanguageByKey(settings?.lang), [
    settings?.lang
  ]);

  return (
    <>
      <IconButton
        ref={arrowRef}
        color="inherit"
        onClick={openLangPopper}
        size="large"
      >
        {showLang ? (
          <>
            <FlagIcon countryCode={currentLang?.flag} />
            <Typography sx={{ mx: 1 }}>{currentLang?.label}</Typography>
          </>
        ) : (
          <SvgIcon fontSize="small">
            <Globe />
          </SvgIcon>
        )}
      </IconButton>
      {!!anchorEl && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement={'bottom'}
            transition
            sx={{
              zIndex: 999,
              maxHeight: 450,
              position: 'relative'
            }}
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef.current
                }
              }
            ]}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  ref={paperRef} // Attach the ref to Paper
                  onScroll={handleScroll} // Handle scroll events
                  sx={{
                    maxHeight: 450,
                    overflow: 'auto'
                  }}
                >
                  <List>
                    {Object.values(LanguagesEnum)
                      .filter(i => !!translations?.[i?.lang?.toUpperCase?.()])
                      .map(l => (
                        <ListItem disablePadding key={l.lang}>
                          <ListItemButton
                            onClick={async () => {
                              await changeLanguage(l);
                              // window.location.reload();
                            }}
                          >
                            <ListItemIcon>
                              <FlagIcon countryCode={l.flag} />
                            </ListItemIcon>
                            <ListItemText primary={l.label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>

                  {/* Bouncing Arrow Icon */}
                  {!atBottom && ( // Hide the arrow when at the bottom
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 5,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        animation: 'bounceArrow 2s ease infinite',
                        opacity: 1,
                        animationDelay: '1s',
                        '@keyframes bounceArrow': {
                          '0%, 20%, 50%, 80%, 100%': {
                            transform: 'translateX(-50%) translateY(0)',
                            opacity: 1
                          },
                          '40%': {
                            transform: 'translateX(-50%) translateY(-10px)'
                          },
                          '60%': {
                            transform: 'translateX(-50%) translateY(-5px)'
                          }
                        }
                      }}
                    >
                      <ArrowDropDownIcon
                        sx={{
                          fontSize: 24,
                          color: 'gray'
                        }}
                      />
                    </Box>
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
